<template>
  <div>
    <b-alert v-if="repprovedDocumentation" show variant="danger" class="p-1 border-danger">
      <strong>Motivo da reprovação: </strong>{{ company.ultima_validacao_documentacao.reason_disapproval }}
    </b-alert>
    <div class="d-flex flex-row justify-content-between align-items-start">
      <div class="d-flex flex-row align-items-center">
        <h3 class="mb-1">
          Documentos representantes legais
        </h3>
      </div>
      <div class="d-flex flex-row align-items-center">
        <b-badge v-if="repprovedDocumentation" class="alert-danger border-danger" pill>
          Reprovado
        </b-badge>
        <b-badge v-if="approvedDocumentation" class="alert-success border-success" pill>
          Aprovado
        </b-badge>
      </div>

      <div class="d-flex flex-row align-items-center">
        <span class="font-weight-bold mr-1">
          Apto para operar
        </span>
        <b-form-checkbox id="canOperate"
                         v-model="company.apto_para_operar"
                         :disabled="true"
                         :readonly="true"
                         switch
                         class="custom-control-success"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </div>
    </div>
    <representative-card v-for="representative in representatives"
                         :key="representative.id"
                         :representative-data="representative"
                         :editable="false"
                         downloadable
    />
    <h3 class="mb-1">
      Documentos da empresa
    </h3>
    <company-document-card :company="company" />

    <b-modal id="reasonDisapproval" centered hide-footer header-bg-variant="white">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="mb-1">
          Motivo da reprovação
        </h3>
        <b-form-textarea id="reason"
                         v-model="reason_disapproval"
                         rows="3"
                         max-rows="6"
                         class="mb-1"
        />
        <h6>Visualização do cliente:</h6>
        <b-alert v-if="reason_disapproval.length" show variant="danger" class="p-1">
          {{ reason_disapproval }}
        </b-alert>
      </div>
    </b-modal>

    <b-modal id="approvalModal" centered hide-footer header-bg-variant="white">
      <div class="d-flex flex-column justify-content-center align-items-center px-2 pt-0 pb-2">
        <h4 class="mb-1">
          Oferta desativada
        </h4>
        <span class="text-center">A oferta de crédito que você está aprovando para esta empresa ainda não está ativada.
          Ative a oferta agora
          clicando no botão “Apto para operar” ou clique em continuar para prosseguir com a oferta desativada.</span>

        <div class="d-flex flex-row align-items-center mt-2">
          <span class="font-weight-bold mr-1">
            Apto para operar
          </span>
          <b-form-checkbox id="canOperate"
                           v-model="company.apto_para_operar"
                           :disabled="true"
                           :readonly="true"
                           switch
                           class="custom-control-success"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BFormTextarea, BModal, BAlert, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import RepresentativeCard from '@/views/client/user/components/RepresentativeCard.vue'
import CompanyDocumentCard from '@/views/admin/companies/components/CompanyDocumentCard.vue'

export default {
  name: 'Documents',
  components: {
    BButton,
    RepresentativeCard,
    CompanyDocumentCard,
    BModal,
    BFormTextarea,
    BAlert,
    BBadge,
    BFormCheckbox,
  },
  data() {
    return {
      company: {},
      representatives: [],
      reason_disapproval: '',
    }
  },
  computed: {
    repprovedDocumentation() {
      return this.company.status_documentacao === 'reprovado'
    },
    approvedDocumentation() {
      return this.company.status_documentacao === 'aprovado'
    },
  },
  async mounted() {
    await this.getDocumentData()
  },
  methods: {
    async approve() {
      try {
        this.$swal.fire({
          title: 'Deseja continuar?',
          text: 'Você está prestes a aprovar a documentação para a empresa.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sim, aprovar!',
        }).then(async result => {
          if (result.value) {
            this.$swal.showLoading()
            await this.$store.dispatch('company/approve', { empresa_id: this.company.id })
            await this.getDocumentData()
            this.$swal.fire('Aprovado!', 'Documentação aprovada com sucesso.', 'success')
            this.hideApprovalModal()
          }
        })
      } catch (error) {
        console.log(error)
        this.$swal.fire('Erro!', 'Ocorreu um erro ao aprovar a documentação.', 'error')
      }
    },
    repprove() {
      try {
        this.$swal.fire({
          title: 'Deseja continuar?',
          text: 'Você está prestes a reprovar a documentação para a empresa.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sim, reprovar!',
        }).then(async result => {
          if (result.value) {
            this.$swal.showLoading()
            await this.$store.dispatch('company/repprove', { empresa_id: this.company.id, reason_disapproval: this.reason_disapproval })
            await this.getDocumentData()
            this.hideModal()
            this.$swal.fire('Reprovado!', 'Documentação reprovada com sucesso.', 'success')
          }
        })
      } catch (error) {
        console.log(error)
        this.$swal.fire('Erro!', 'Ocorreu um erro ao reprovar a documentação.', 'error')
      }
    },
    hideModal() {
      this.$bvModal.hide('reasonDisapproval')
      this.reason_disapproval = ''
    },
    async getDocumentData() {
      const { data } = await this.$store.dispatch('company/fetch', this.$route.params.id)
      this.company = data
      this.representatives = data.representantes_legais
    },
    async approveOrOpenModal() {
      if (this.company.apto_para_operar) {
        await this.approve()
      } else {
        this.$bvModal.show('approvalModal')
      }
    },
    hideApprovalModal() {
      this.$bvModal.hide('approvalModal')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-file-manager";
</style>
